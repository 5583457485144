* {
  font-size: "Russo One";
}

.App {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  overflow: auto;
}

.wrap {
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  background: linear-gradient(180deg, #eac400 0%, #ffef64 50.46%, #eac400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 20px rgba(253, 237, 106, 0.3);
  text-align: center;
  margin-top: 24px;
  margin-bottom: 0;
  font-family: "Russo One", sans-serif;
  font-size: 24px;
  letter-spacing: 0.05em;
  font-weight: 400;
}

h2 {
  background: linear-gradient(180deg, #eac400 0%, #ffef64 50.46%, #eac400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 20px rgba(253, 237, 106, 0.3);
  text-align: center;
  margin-top: 12px;
  margin-bottom: 12px;
  font-family: "Russo One", sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: 400;
}

section {
  background: rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  margin-top: 34px;
  border-radius: 16px;
}

section.winnersGlobal,
section.winnersLocal {
  margin-top: 16px;
}

.local__jackpots {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  padding: 0 6px;
}

footer {
  margin-top: 40px;
  padding: 0 12px;
  padding-bottom: 32px;
}

.footer__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__social {
  display: flex;
  flex-direction: row;
}

.footer__socialItem {
  margin-right: 8px;
  height: 48px;
  width: 48px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  background-position: center;
  cursor: pointer;
  line-height: 0;
  font-size: 0;
  color: transparent;
}

.footer__socialItem--fb {
  background-image: url("./images/fb.svg");
}

.footer__socialItem--insta {
  background-image: url("./images/insta.svg");
}

.footer__socialItem--tg {
  background-image: url("./images/tg.svg");
}

.footer__infoPage {
  min-height: 10px;
  padding: 12px 20px;
  background: linear-gradient(180deg, #eac400 0%, #ffef64 50.46%, #eac400 100%);
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
}

.footer__infoPageText {
  background: linear-gradient(
    180deg,
    #0f132a 0%,
    #323c8c 53.12%,
    #151c53 97.92%
  );
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.footer__copyright {
  margin-top: 24px;
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.geometria-symbol {
  font-family: "Geometria", sans-serif;
  font-weight: 800;
  padding-left: 3px;
  padding-right: 2px;
}

@media (max-width: 400px) {
  section {
    margin: 0 8px;
  }
}
