@font-face {
  font-family: "Russo One";
  src: url("./RussoOne-Regular.eot");
  src: local("Russo One"), local("RussoOne-Regular"),
    url("./RussoOne-Regular.eot?#iefix") format("embedded-opentype"),
    url("./RussoOne-Regular.woff") format("woff"),
    url("./RussoOne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DM Mono";
  src: local("DM Mono Regular"), local("DM-Mono-Regular"),
    url("./DMMono-Regular.woff2") format("woff2"),
    url("./DMMono-Regular.woff") format("woff"),
    url("./DMMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "DM Mono";
  src: local("DM Mono Medium"), local("DM-Mono-Medium"),
    url("./DMMono-Medium.woff2") format("woff2"),
    url("./DMMono-Medium.woff") format("woff"),
    url("./DMMono-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria Bold"), local("Geometria-Bold"),
    url("./geometria/Geometria-Bold.woff2") format("woff2"),
    url("./geometria/Geometria-Bold.woff") format("woff"),
    url("./geometria/Geometria-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: local("Geometria ExtraBold"), local("Geometria-ExtraBold"),
    url("./geometria/Geometria-ExtraBold.woff2") format("woff2"),
    url("./geometria/Geometria-ExtraBold.woff") format("woff"),
    url("./geometria/Geometria-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
