.placeholder {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholder__title {
  margin-top: 24px;
  width: 180px;
  height: 38px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
}

.placeholder__jackpot {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  min-height: 102px;
  margin: 12px;
  margin-bottom: 0;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
}

.placeholder__jackpotTitle {
  width: 118px;
  height: 20px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
}

.placeholder__jackpotAmount {
  margin-top: 10px;
  align-self: stretch;
  height: 36px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 22px;
}

.placeholder__jackpot--first .placeholder__jackpotAmount {
  height: 62px;
}

.placeholder__spin {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 146px;
  background: rgba(255, 255, 255, 0.1);
}

.placeholder__spinSkip {
  top: 17px;
  right: 11px;
  position: absolute;
  width: 32px;
  height: 16px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 20px;
}

.placeholder__spinTitle {
  margin-top: 17px;
  width: 208px;
  height: 23px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 42px;
}

.placeholder__spinSubtitle {
  margin-top: 8px;
  width: 108px;
  height: 23px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 42px;
}

.placeholder__spinButton {
  margin-top: 16px;
  width: 336px;
  height: 48px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 40px;
}
