.spin {
  display: flex;
  width: 100vw;
  height: 146px;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    180deg,
    #0f132a 0%,
    #2a316c 53.12%,
    #151c53 97.92%
  );
  position: fixed;
  transition: all 0.3s ease-in-out;
  bottom: 0;
  z-index: 4;
}

.spin--hidden {
  bottom: -146px;
}

.spin__skip {
  position: absolute;
  right: 11px;
  top: 17px;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-shadow: 0px 2px 20px rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

h1.spin__letsPlay {
  margin-top: 16px;
  margin-bottom: 0;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 24px;
  text-transform: uppercase;
  background: linear-gradient(180deg, #eac400 0%, #ffef64 50.46%, #eac400 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.spin__winner {
  margin-top: 6px;
  margin-bottom: 0;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.spin__button {
  margin: 0 12px;
  margin-top: 16px;
  align-self: stretch;
  height: 48px;
  line-height: 48px;
  background: linear-gradient(180deg, #eac400 0%, #ffef64 50.46%, #eac400 100%);
  border-radius: 4px;
  border: 0;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.spin__buttonText {
  background-image: linear-gradient(
    180deg,
    #0f132a 0%,
    #323c8c 53.12%,
    #151c53 97.92%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
}
