.win {
  margin: 12px;
  margin-top: 0;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.14);
  border: 4px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.win__top,
.win__bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.win__right {
  align-items: flex-end;
}

.win__name {
  margin-bottom: 4px;
  padding: 1px 8px 0px;
  background: linear-gradient(180deg, #db1c1c 0%, #fd6949 51.69%, #db1d1d 100%);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}

.win--yellow .win__name {
  background: linear-gradient(180deg, #ea9a00 0%, #ffcb64 50.46%, #ea9c00 100%);
}

.win--green .win__name {
  background: linear-gradient(180deg, #0ca036 0%, #09dc45 49.42%, #05b537 100%);
}

.win--blue .win__name {
  background: linear-gradient(180deg, #4b47ff 0%, #817fff 52.6%, #4a47ff 100%);
}

.win--red .win__name {
  background: linear-gradient(180deg, #db1c1c 0%, #fd6949 51.69%, #db1d1d 100%);
}

.win__barcode {
  margin-left: 8px;
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}

.win__amount {
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}

.win__date {
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}


@media (max-width: 400px) {
  .win {
    margin: 6px;
    margin-top: 0;
    padding: 8px 6px;
  }
}