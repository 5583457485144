.jackpot {
  position: relative;
  margin: 12px;
  margin-bottom: 0;
  display: flex;
  box-shadow: 0px 0px 32px rgba(255, 0, 0, 0.4);
  border-radius: 16px;
}

.jackpot__animationWrapper {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.jackpot__animation {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 51%
  );
}

.jackpot--animated .jackpot__amountWrapper {
  animation: blink 0.8s ease-in-out infinite;
}

@keyframes blink {
  from {
    background-color: #31345b;
  }
  50% {
    background-color: #fff;
  }
  to {
    background-color: #31345b;
  }
}

.jackpot--animated .jackpot__animation {
  display: block;
  opacity: 1;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: scale3d(2, 2, 1) rotateZ(0deg);
  }
  to {
    transform: scale3d(2, 2, 1) rotateZ(-360deg);
  }
}

.jackpot__content {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2px;
  padding: 16px;
  z-index: 3;
}

.jackpot--red,
.jackpot--red .jackpot__content {
  background: linear-gradient(
    180deg,
    #ff3838 0%,
    #fd6949 33.1%,
    #ff9e88 51.69%,
    #fd6949 68.07%,
    #ff3838 100%
  );
}

.jackpot--green,
.jackpot--green .jackpot__content {
  background: linear-gradient(180deg, #0ca036 0%, #09dc45 49.42%, #05b537 100%);
}

.jackpot--yellow,
.jackpot--yellow .jackpot__content {
  background: linear-gradient(180deg, #ea9a00 0%, #ffcb64 50.46%, #ea9c00 100%);
}

.jackpot--blue,
.jackpot--blue .jackpot__content {
  background: linear-gradient(180deg, #4b47ff 0%, #817fff 52.6%, #4a47ff 100%);
}

.jackpot__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.jackpot__topTitle {
  font-family: "Russo One", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6), 0px 0px 5px #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.jackpot__topTitle--type {
  text-transform: capitalize;
}

.jackpot__topTitle--name {
  font-size: 12px;
}

.jackpot__topDescription {
  margin-left: 5px;
  font-family: "DM Mono", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
  white-space: nowrap;
}

.jackpot__amountWrapper {
  margin-top: 6px;
  background-color: #31345b;
  padding: 4px;
  border-radius: 8px;
  height: 32px;
}

.jackpot__amount {
  background: rgba(16, 19, 55, 0.9);
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  font-family: "Russo One", sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.08em;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.jackpot--small {
  position: relative;
  display: flex;
  margin: 6px;
  margin-top: 0;
  margin-bottom: 12px;
  border-radius: 12px;
  width: calc(50% - 12px);
}

.jackpot--small .jackpot__topTitle {
  font-size: 10px;
  line-height: 16px;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
  font-weight: 400;
}

.jackpot--small .jackpot__topDescription {
  font-size: 8px;
  line-height: 16px;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}

.jackpot--small .jackpot__amountWrapper {
  margin-top: 6px;
  background-color: #31345b;
  border-radius: 8px;
  height: 28px;
}

.jackpot--small .jackpot__amount {
  height: 28px;
  border-radius: 8px;
  padding: 4px;
  margin: 0px;
  font-size: 17px;
  line-height: 20px;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6),
    0px 0px 10px rgba(255, 255, 255, 0.2);
}

.jackpot--small .jackpot__animationWrapper {
  border-radius: 12px;
}

.jackpot--small .jackpot__animation {
  border-radius: 12px;
}

.jackpot--small .jackpot__content {
  width: calc(100% - 18px);
  border-radius: 12px;
  margin: 2px;
  padding: 8px;
}

.jackpot--first .jackpot__amountWrapper {
  height: 62px;
}

.jackpot--first .jackpot__amount {
  height: 62px;
  line-height: 62px;
}

.jackpot__amount__value {
  word-spacing: -7px;
}

.jackpot--small .jackpot__amount__value {
  word-spacing: -3px;
}
